import dayjs from "dayjs";
import { useStyles } from "../styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  SubsicriptionSearchType,
  SubsicriptionType,
} from "Types/SubsicriptionType";
import { IPagedSearch } from "Types/Paged";
import SharedTable from "Components/Shared/SharedTable";
import { string } from "yup";

type IProps = {
  data?: Array<SubsicriptionType>;
  search: IPagedSearch<SubsicriptionSearchType>;
  setSearch: (e: any) => void;
  total: number;
  onStart: (id: number) => void;
};

const SubsicriptionTable = ({ data, search, setSearch, total, onStart }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation("subsicription");
  const columns = [
    {
      field: "userId",
      headerName: t("userId"),
      width: 150,
      sortable: false,
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>{t("userId")}</Typography>
        );
      },
    },
    {
      field: "name",
      headerName: t("namsdfeHeader"),
      width: 150,
      sortable: false,
      renderCell: (params: any) => {
        return params.row?.name;
      },
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("nameHeader")}
          </Typography>
        );
      },
    },
    {
      field: "mobile",
      headerName: t("mobileHeader"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("mobileHeader")}
          </Typography>
        );
      },
    },
    {
      field: "packageId",
      headerName: t("packageId"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("packageId")}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: t("type"),
      width: 150,
      sortable: false,
      renderCell: (params: any) => {
        return params.row?.type;
      },
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("type")}
          </Typography>
        );
      },
    },
    {
      field: "balance",
      headerName: t("balance"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("balance")}
          </Typography>
        );
      },
    },
    {
      field: "createdOn",
      headerName: t("createdOn"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return dayjs(params.row.createdOn).format("DD-MM-YYYY HH:mm:ss");
      },
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("createdOn")}
          </Typography>
        );
      },
    },
    {
      field: "expiresOn",
      headerName: t("expiresOn"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return dayjs(params.row.expiresOn).format("DD-MM-YYYY HH:mm:ss");
      },
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("expiresOn")}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) =>
        !params.row.expiresOn ?
          <Button onClick={() => onStart(Number(params.row.id))} variant="contained" color="primary">{t("activate")}</Button>
          : <></>
      ,
      renderHeader: () => {
        return (
          <Typography className={classes.tableHeader}>
            {t("actions")}
          </Typography>
        );
      },
    },
  ];
  return (
    <SharedTable
      columns={columns}
      rows={data ?? []}
      pageSize={search?.limit}
      onPageChange={(i) => setSearch({ ...search, offset: i * search.limit })}
      onPageSizeChange={(i) => setSearch({ ...search, limit: i })}
      rowCount={total}
      page={Math.ceil(search?.offset / search?.limit)}
      getRowId={(i: any) => {
        return i.id;
      }}
    />
  );
};
export default SubsicriptionTable;
