import SubsicriptionClient from "Api/subsicriptionClient";
import { SetIsLoading } from "State/Actions/UiActions";
import { IPagedSearch } from "Types/Paged";
import {
  SubsicriptionSearchType,
  SubsicriptionType,
} from "Types/SubsicriptionType";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SubsicriptionSearch from "./components/search";
import SubsicriptionTable from "./components/table";
import { Paper } from "@material-ui/core";

const Subsicriptions = () => {
  let client = new SubsicriptionClient();
  const [search, setSearch] = useState<IPagedSearch<SubsicriptionSearchType>>({
    limit: 25,
    offset: 0,
    search: {
      mobile: "",
      packageId: null,
      from: null,
      to: null,
    },
  });
  const [count, setCount] = useState<number>(0);
  const [results, setResults] = useState<SubsicriptionType[]>([]);
  const dispatch = useDispatch();

  const refresh = async (obj?: IPagedSearch<SubsicriptionSearchType>) => {
    dispatch(SetIsLoading(true));
    let res = await client.SearchAsync(obj ?? search);
    if (res.succeeded) {
      setResults(res.data?.matches);
      setCount(res.data?.total);
    }
    dispatch(SetIsLoading(false));
  };

  const handleStartSubscription = async (id: number) => {
    dispatch(SetIsLoading(true));
    let res = await client.StartAsync(id);
    if (res.succeeded) {
      await refresh()
    }
    dispatch(SetIsLoading(false));
  }

  useEffect(() => {
    refresh().then();
  }, [search.limit, search.offset]);

  return (
    <Fragment>
      <SubsicriptionSearch
        onSearch={refresh}
        search={search}
        setSearch={(e) => setSearch(e)}
        onClear={() => {
          const obj = {
            ...search,
            search: {
              mobile: "",
              from: null,
              to: null,
              packageId: null,
            },
          };
          setSearch(obj);
          refresh(obj).then();
        }}
      />

      <Paper
        elevation={2}
        style={{ marginTop: "2rem", width: "100%", height: "50vh" }}
      >
        <SubsicriptionTable
          data={results ?? []}
          total={count}
          search={search}
          setSearch={setSearch}
          onStart={handleStartSubscription}
        />
      </Paper>
    </Fragment>
  );
};
export default Subsicriptions;